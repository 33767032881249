<template>
  <div class="notice-board-page">
    <v-row no-gutters class="my-2" justify="center">
      <v-col md="6">
        <intract-smart-list
          ref="notice-posts-smart-list"
          :endpoint="noticePostsEndpoint"
          paginated
          disable-search
          :filter-fields="showFilterOptions ? filterFields : null"
        >
          <template v-slot:custom-filter--dateRange>
            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  solo
                  clearable
                  @click:clear="date = null"
                  :value="formattedDateRangeString"
                  label="Filter by Date"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  hide-details="auto"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date" range>
                <v-row no-gutters>
                  <v-col cols="12" class="text-center">
                    <span
                      v-if="date && date.length == 1"
                      class="text-caption red--text"
                      >Please select End Date as well</span
                    >
                  </v-col>
                  <v-col class="text-right">
                    <v-btn text color="primary" @click="modal = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      :disabled="date && date.length == 1"
                      color="primary"
                      @click="$refs.dialog.save(date)"
                    >
                      OK
                    </v-btn>
                  </v-col>
                </v-row>
              </v-date-picker>
            </v-dialog>
          </template>
          <template v-slot:list-item="{ item: post }">
            <v-card
              class="mx-2 my-3"
              @click="
                $router.push({
                  name: 'ViewBoardPost',
                  params: { postId: post.id },
                })
              "
            >
              <v-list-item>
                <v-list-item-avatar>
                  <v-img :src="currentInstitution.logo"></v-img>
                </v-list-item-avatar>
                <v-list-item-content class="subtitle-2">
                  {{ currentInstitution.name }}
                </v-list-item-content>
                <p class="my-0 caption grey--text">
                  {{ moment(post.created).format("hh:mmA, Do MMM") }}
                </p>
              </v-list-item>
              <v-card-text class="py-1 pb-2">
                <div class="black--text subtitle-1 content-description">
                  {{ post.description | truncate(150, "...") }}
                </div>
                <v-img
                  v-if="post.board_images.length"
                  :src="post.board_images[0].image"
                ></v-img>
              </v-card-text>
              <v-card-actions
                class="pt-0 ml-1"
                v-if="
                  post.board_images.length ||
                  post.board_documents.length ||
                  post.is_draft
                "
              >
                <v-chip
                  small
                  color="orange lighten-2 mr-1"
                  v-if="post.is_draft && post.scheduled"
                  >Scheduled</v-chip
                >

                <v-chip
                  small
                  color="light-blue lighten-4 mr-1"
                  v-if="
                    post.board_images.length && post.board_images.length > 1
                  "
                  >+{{ post.board_images.length - 1 }} Images</v-chip
                >
                <v-chip
                  small
                  color="teal lighten-4"
                  v-if="post.board_documents.length"
                  >{{ post.board_documents.length }} Document(s)</v-chip
                >
              </v-card-actions>
            </v-card>
            <!-- <v-divider class="mx-4"></v-divider>         -->
          </template>
        </intract-smart-list>

        <!-- </div> -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import IntractSmartList from "@components/generics/IntractSmartList";
import moment from "moment";
import { mapGetters } from "vuex";
import EventBus from "@utils/event_bus";
import Mixins from '@utils/mixins';

export default {
  name: "BoardPosts",
  props: {
    showFilterOptions: {
      type: Boolean,
      default: false,
    },
    for_staff: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    IntractSmartList,
  },
  data() {
    return {
      date: null,
      modal: false,
      moment: moment,
    };
  },
  mixins: [Mixins.essentials, Mixins.pullToRefresh, Mixins.handleAppBarOptions],
  computed: {
    ...mapGetters([
      "currentInstitution",
    ]),

    noticePostsEndpoint() {
      var endpoint_url = this.Helper.addUrlParams(this.endpoints.boardPosts, ["is_alert=false", `for_staff=${this.for_staff}`]) 
      
      if(this.currentUser.is_student) //hide drafts
        endpoint_url = this.Helper.addUrlParams(endpoint_url, ['is_draft=false']); 

      // Filter by date
      var dateFilter = "";
      if (this.showFilterOptions && this.date && this.date.length == 2) {
        var startDate, endDate = null;
        if (moment(this.date[0]).isSameOrBefore(moment(this.date[1]))) {
          startDate = this.date[0];
          endDate = this.date[1];
        } else {
          startDate = this.date[1];
          endDate = this.date[0];
        }
        dateFilter = `created__gte=${moment(startDate).format(this.Helper.dateURLFormat)}
                     &created__lte=${moment(endDate).endOf('day').format(this.Helper.dateURLFormat)}`;
 
        endpoint_url = this.Helper.addUrlParams(endpoint_url, dateFilter);
      }
      return endpoint_url;
    },

    filterFields() {
      return {
        dateRange: {
          model: null,
          label: "Filter by Date",
          custom: true,
        },
      };
    },

    formattedDateRangeString() {
      if (!this.date) return null;
      if (this.date.length == 0) return "";
      else if (this.date.length == 1)
        return moment(this.date[0]).format("ll") + " - ";
      else
        return (
          moment(this.date[0]).format("ll") +
          " - " +
          moment(this.date[1]).format("ll")
        );
    },
  },

  methods: {
    async initListeners() {
      EventBus.$on(
        "view_board_post__post_deleted",
        // (id) => (this.posts = this.posts.filter((p) => p.id != id))
        (id) => (this.$refs["notice-posts-smart-list"].removeItem(id)),   
      );

      EventBus.$on("view_board_post__post_updated", (post) => {
        if (this.$refs["notice-posts-smart-list"])
          this.$refs["notice-posts-smart-list"].replaceItem(post.id, post);
      });

      if (this.for_staff)
        EventBus.$on("refreshStaffBoardPosts", () => {
          if (this.$refs["notice-posts-smart-list"])
            this.$refs["notice-posts-smart-list"].getItems();
        });
      else
        EventBus.$on("refreshAllBoardPosts", () => {
          if (this.$refs["notice-posts-smart-list"])
            this.$refs["notice-posts-smart-list"].getItems();
        });
    },  
  },

  created() {
    this.initListeners();
  },
};
</script>